import retry from "async-retry"
import { ApiConfig } from "./ConstantService"

async function DoCheckEntity(token, request) {
  const resp = await fetch(
    ApiConfig.endpoint + "/check", {
    method: "POST",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  if (data.error)
    throw new Error(data.error)
  return data;
}

export async function CheckEntity(token, request) {
  return await retry(
    async (bail) => {
      try {
        return await DoCheckEntity(token, request)
      } catch (error) {
        const msg = error.message
        // Only retry upon 429 error
        if (!/too many 429/.test(msg)) {
          bail(error)
          return
        }
        throw error
      }
    },
    {
      retries: 3,
      factor: 1,
      minTimeout: 10000,
      maxTimeout: 10000,
    }
  )
}

export async function GetUsers(token, is_admin) {
  let url_path = is_admin ? "/admin_users" : "/users"
  const resp = await fetch(
    ApiConfig.endpoint + url_path, {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  return data;
}

export async function SetUsers(token, users, is_admin) {
  let url_path = is_admin ? "/admin_users" : "/users"
  const resp = await fetch(
    ApiConfig.endpoint + url_path, {
    method: "PUT",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(users),
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  return data;
}
