import * as XLSX from 'xlsx';

import { ExcelColumn, ExcelNameColumn } from './ConstantService';


function LoadExcelData(fileData) {
  const workbook = XLSX.read(fileData, {
    type: 'binary',
    cellDates: true,
  });
  let entries = Object.entries(ExcelColumn).map(([k, v]) => {
    let sheet = workbook.Sheets[v];

    // Skip first 3 rows
    let range = XLSX.utils.decode_range(sheet['!ref'])
    range.s.r = Math.min(range.s.r + 3, range.e.r);
    sheet['!ref'] = XLSX.utils.encode_range(range)

    let sheetData = XLSX.utils.sheet_to_json(sheet, { UTC: true });

    // Filter invalid rows
    let filterData = sheetData.filter(v => {
      return v['No'] !== 'Example' && v[ExcelNameColumn[k]]
    })

    return [k, filterData]
  })
  let data = Object.fromEntries(entries)
  data.Campaign = NormaliseCampaign(data.Campaign)
  return data
}

function NormaliseCampaign(campaigns) {
  return campaigns.map(e => {
    const entries = Object.entries(e).map(([k, v]) => {
      let value = v
      if (/Flight.*Date/.test(k) || k === "StartDateUtc") {
        value = v.getYear() <= 1 ? null : v.toLocaleDateString('sv-SE')
      }
      if (/Flight.*Time/.test(k)) {
        value = v.toLocaleTimeString('sv-SE')
      }
      return [k, value]
    })
    return Object.fromEntries(entries)
  })
}

export async function GetExcel(file) {
  let buffer = await file.arrayBuffer();
  let data = LoadExcelData(buffer)
  return data
}
