import { CheckTypes, ExcelNameColumn } from "./ConstantService";
import { GetExcel } from "./FileService";
import { CheckEntity } from "./ApiService";

export async function Check(token, request, setResult) {
  const file = request.file
  const data = await GetExcel(file)
  // const t1 = JSON.stringify(data.AdGroup[1], null, 2)
  const advertiserId = data.Advertiser[0]["Advertiser ID"]
  const promises = CheckTypes.map(async type => {
    let objs = data[type]
    objs.forEach(e => { e["Advertiser ID"] = advertiserId });
    return await CheckObjects(token, type, objs, setResult)
  })
  await Promise.all(promises)
}

async function CheckObject(token, type, obj) {
  const request = {
    type: type,
    data: { item: obj },
  }
  const resp = await CheckEntity(token, request)
  const item = resp.result.item
  return {
    "Advertiser ID": item["Advertiser ID"],
    "No": item["No"],
    "Name": item[ExcelNameColumn[type]],
    "Validate Error": item["Validate Error"],
    "ID": item["ID"],
  }
}

async function CheckObjects(token, type, objs, setResult) {
  let all_results = []
  for (let obj of objs) {
    let result = await CheckObject(token, type, obj)
    all_results = all_results.concat(result)
    setResult(type, all_results)
  }
  return all_results
  // let all_results = []
  // let promises = objs.map(async obj => {
  //   let result = await CheckObject(token, type, obj)
  //   all_results = all_results.concat(result).sort((a, b) => parseInt(a["No"]) - parseInt(b["No"]))
  //   setResult(type, all_results)
  // })
  // await Promise.all(promises)
  // return all_results
}
