import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Navigation = (props) => {
  return (
    <Navbar expand="lg">
      <Container fluid>
        <img src="images/TTD_Lockup_BlackInk.png" height="15" alt="Logo1" style={{ paddingRight: "2%" }} />
        <Navbar.Brand href="/">Main</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/Users">Users</Nav.Link>
          </Nav>
          <Nav.Link>{props.User.email}</Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;