import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { CheckTypes } from "../services/ConstantService";
import { Check } from "../services/CheckService";

function FileInput({ onChange }) {
  return (
    <Form.Group>
      <Form.Label>
        <strong>Upload an Excel file</strong>
      </Form.Label>
      <Form.Control
        type="file"
        onChange={onChange} />
    </Form.Group>
  )
}

function SubmitButton({ file, state, onClick }) {
  let text = state.is_processing ? " Processing..." : "Submit"
  let disabled = !file || state.is_processing
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      <Spinner as="span" animation="border" size="sm"
        role="status" aria-hidden="true" hidden={!state.is_processing} />
      {text}
    </Button>
  )
}

function CheckErrorView({ value }) {
  let msg = value.error
  if (msg.startsWith("Failed to fetch") || msg.startsWith("NetworkError when attempting to fetch resource")) {
    msg = "Login expired. Please refresh the page and submit again."
  }
  return (
    <>
      <p>Got the following error from server. Please double check your input, refresh the page, and submit again. If it still fails, please contact administrator.</p>
      <p style={{ color: 'red' }}>{msg}</p>
    </>
  )
}

function CheckResultValidateErrorView({ value }) {
  const items = Object.entries(value).map(([k, v]) =>
    `${k}: expected "${v["expected"]}"; actual "${v["actual"]}"`
  )
  const text = items.length ? items.join("\r\n") : "No errors found"
  return text
}

function CheckResultItemView({ name, value }) {
  const bgcolor = Object.values(value["Validate Error"]).length > 0 ? 'lightgray' : 'none'
  const style = { backgroundColor: bgcolor, whiteSpace: "break-spaces" }
  let url_or_name = value["Name"]
  let url = null
  if (value["ID"]) {
    const prefix = `https://desk.thetradedesk.com/app/advertiser/${value["Advertiser ID"]}`
    if (name === "Campaign") {
      url = `${prefix}/buy/campaign/${value["ID"]}/details`
    } else if (name === "AdGroup") {
      url = `${prefix}/buy/adgroup/${value["ID"]}`
    } else if (name === "Contract") {
      url = `${prefix}/inventory/contract-details/${value["ID"]}`
    } else {
      throw new Error(`Unknown type ${name}`)
    }
    url_or_name = <a href={url}>{value["Name"]}</a>
  }

  return (
    <>
      <td style={style} key="No">{value["No"]}</td>
      <td style={style} key="Name">{url_or_name}</td>
      <td style={style} key="Validate Error"><CheckResultValidateErrorView value={value["Validate Error"]} /></td>
    </>
  )
}

function CheckResultView({ name, value }) {
  if (!value || !value.length) {
    return <></>
  }
  const items = value.map((v, i) =>
    <tr key={i}><CheckResultItemView name={name} value={v} /></tr>
  )
  return (
    <>
      <h4>{name} Results</h4>
      <Table bordered hover>
        <thead>
          <tr key="header">
            <th key="No">No</th>
            <th key="Name">Name</th>
            <th key="Validate Error">Validate Error</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table>
    </>
  )
}

function CheckResponseView({ value }) {
  if (value.error) {
    return <CheckErrorView value={value} />
  }

  const resultViews = CheckTypes.map(type =>
    <div key={type}><CheckResultView name={type} value={value.results[type]} /></div>
  )

  return (
    <> {resultViews} </>
  )
}

function Main() {
  const auth = useAuth();
  const [selectedFile, setSelectedFile] = useState({
    file: null,
  });
  const [submitState, setSubmitState] = useState({
    is_processing: false,
  });
  const [checkResponse, setCheckResponse] = useState({
    file: null,
    error: null,
    results: {},
  });
  let respResults = {}

  function handleFileChange(event) {
    let file = event.target.files[0]
    setSelectedFile({ ...selectedFile, ...{ file: file } })
  }

  function setResult(type, results) {
    respResults[type] = results
    setCheckResponse({
      file: selectedFile.file,
      results: respResults,
    })
  }

  async function handleSubmit(event) {
    try {
      setSubmitState({
        is_processing: true
      })
      await Check(auth.user.id_token, selectedFile, setResult)
    } catch (err) {
      setCheckResponse({
        file: selectedFile.file,
        error: err.message
      })
    }
    finally {
      setSubmitState({
        is_processing: false
      })
    }
  }

  return (
    <div>
      <h1>TTD CyberComm QA Tool</h1>
      <hr />
      <Container fluid>
        <Row>
          <Col lg={4}>
            <Form>
              <div><FileInput onChange={handleFileChange} /></div>
              <br />
              <div><SubmitButton file={selectedFile.file} state={submitState} onClick={handleSubmit} /></div>
            </Form>
          </Col>
          <Col lg={8} />
        </Row>
      </Container>
      <hr />
      <div><CheckResponseView value={checkResponse} /></div>
    </div>
  )
}

export default Main