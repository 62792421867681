import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from '../components/Main';
import Users from '../components/Users'
import MainLayout from "../components/MainLayout";

function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <MainLayout>
            <Main />
          </MainLayout>
        } />
        <Route path="/loginCallback" element={
          <MainLayout>
            <Main />
          </MainLayout>
        } />
        <Route path="/Users" element={
          <MainLayout>
            <Users />
          </MainLayout>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
