export const ApiConfig = {
  endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
}

export const OidcConfig = {
  authority: process.env.REACT_APP_COGNITO_USERPOOL_ENDPOINT,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COGNITO_CALLBACK_URL,
};

export const ExcelColumn = {
  Advertiser: "API Advertiser",
  Campaign: "API Campaign",
  AdGroup: "API Adgroup",
  Contract: "API Contracts",
};

export const ExcelNameColumn = {
  Advertiser: "Advertiser Name",
  Campaign: "Campaign name",
  AdGroup: "Adgroup name",
  Contract: "Contract Name",
};

export const CheckType = {
  ADVERTISER: "Advertiser",
  CAMPAIGN: "Campaign",
  ADGROUP: "AdGroup",
  CONTRACT: "Contract",
};

export const CheckTypes = [
  CheckType.CAMPAIGN,
  CheckType.CONTRACT,
  CheckType.ADGROUP,
]